//Core
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import get from 'lodash.get';
import formatDate from 'date-format';
//Local
import { Card } from '../../../../components';
import { recommendStyles } from '../styles';
import { getArticleLink } from '../../article/utils';

const ReccomendSec = ({ recommendedArticles }) => {
  const styles = recommendStyles();

  const recommendedArticlesJSX = recommendedArticles.map((item, index) => (
    <Grid xs={12} md={4} key={index} item>
      <Card
        img={get(item, 'node.previewImage.file.url')}
        title={get(item, 'node.title')}
        categories={get(item, 'node.categories', []).map(
          (itemm) => itemm.title
        )}
        link={getArticleLink(item.node)}
        variant="primary"
        theme="dark"
        size="sm"
        date={formatDate('yyyy/MM/dd', new Date(get(item, 'node.date')))}
      />
    </Grid>
  ));

  return (
    <section className={styles.reccomendSec}>
      <Typography className={styles.title} variant="h3">
        こちらもオススメ
      </Typography>
      <Grid container spacing={3}>
        {recommendedArticlesJSX}
      </Grid>
    </section>
  );
};

export default ReccomendSec;
