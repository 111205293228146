/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const articleStyles = makeStyles(() => ({
  articlePage: {
    position: 'relative',
    padding: '100px 0',
  },
  articlePageBg: {
    height: 480,
    width: '100%',
    position: 'absolute',
    zIndex: '-1',
    top: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  paper: {
    padding: '55px 80px 95px 80px',
    boxShadow: '0px 12px 34px rgba(0, 0, 0, 0.15)',
    borderRadius: '24px',
    backgroundColor: '#fff',
  },
  '@media screen and (max-width: 600px)': {
    container: {
      padding: 0,
    },
    paper: {
      padding: 16,
    },
  },
}));

export const jobStyles = makeStyles(() => ({
  jobPage: {
    position: 'relative',
    padding: '100px 0',
  },
  jobPageBg: {
    height: 480,
    width: '100%',
    position: 'absolute',
    zIndex: '-1',
    top: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  paper: {
    padding: '55px 80px 95px 80px',
    boxShadow: '0px 12px 34px rgba(0, 0, 0, 0.15)',
    borderRadius: '24px',
    backgroundColor: '#fff',
  },
  '@media screen and (max-width: 600px)': {
    paper: {
      padding: 16,
    },
  },
}));
