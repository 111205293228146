/* eslint-disable import/prefer-default-export */
import get from 'lodash.get';

export const getArticleLink = (item) => {
  if (get(item, 'blogSection.urlKey') && get(item, 'contentful_id')) {
    return `/media_center/${get(item, 'blogSection.urlKey')}/article/${get(
      item,
      'contentful_id'
    )}`;
  }

  return null;
};
