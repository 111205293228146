// @flow
//Core
import React from 'react';
import classNames from 'classnames';
import { Grid, Box, Modal, Typography, Link } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
//Local
import typography from '../../../../styles/typography.module.scss';
import { ShareButtons } from '../../../../components';
import { articleStyles } from '../styles';

const ArticleSec = ({
  title,
  subtitle,
  date,
  author,
  tags,
  content,
  imgPopupState,
  handleClosePopup,
  handleImgClick,
  shareUrl,
  sessionUrl,
}: {
  title: string,
  subtitle: string,
  date: string,
  author: string,
  tags: Array<Object>,
  content: Object,
  imgPopupState: Object,
  handleClosePopup: Function,
  handleImgClick: Function,
  shareUrl: string,
  sessionUrl: string,
}) => {
  const styles = articleStyles();
  const tagsJSX = tags.map((tag) => (
    <Typography
      className={classNames(styles.tag, typography.textSmDark)}
      variant="subtitle1"
      component={Link}
      key={tag.id}
      href={`/media_center/${sessionUrl}/#${tag.title}`}
    >
      {tag.title}
    </Typography>
  ));
  const options = {
    renderText: (text) =>
      text
        .split('\n')
        .flatMap((modifiedText, i) => [i > 0 && <br key={i} />, modifiedText]),
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Typography variant="h1">{children}</Typography>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography variant="h2">{children}</Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography variant="h3">{children}</Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography variant="h4">{children}</Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography variant="h5">{children}</Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography variant="h6">{children}</Typography>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography component="p" variant="body1">
          {children}
        </Typography>
      ),
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.QUOTE]: (node, children) => (
        <Typography component="blockquote" variant="subtitle1">
          {children}
        </Typography>
      ),
      [BLOCKS.HR]: () => <hr />,
      [INLINES.HYPERLINK]: (node, children) => {
        return <Link href={node.data.uri}>{children}</Link>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <button onClick={handleImgClick} className={styles.contentImgWrap}>
            <img
              key={
                node.data.target.fields.file[
                  Object.keys(node.data.target.fields.file)[0]
                ].url
              }
              src={
                node.data.target.fields.file[
                  Object.keys(node.data.target.fields.file)[0]
                ].url
              }
              alt=""
            />
          </button>
        );
      },
    },
  };
  const contentJSX = documentToReactComponents(content, options);

  return (
    <>
      <section className={styles.articleSec}>
        <Typography
          component="h1"
          variant="h3"
          className={classNames(typography.withDividerLeftPurple, styles.title)}
        >
          {title}
        </Typography>
        <Typography className={styles.subtitle} variant="body1">
          {subtitle}
        </Typography>
        <Grid
          container
          justify="space-between"
          alignItems="flex-end"
          className={styles.info}
        >
          <Grid item>
            <Typography className={classNames(styles.date)} variant="subtitle1">
              {date}
            </Typography>
            <Typography
              className={classNames(styles.author)}
              variant="subtitle1"
            >
              {author}
            </Typography>
          </Grid>
          <Grid item>
            <ShareButtons url={shareUrl} />
          </Grid>
        </Grid>
        <Box className={classNames(styles.content)}>{contentJSX}</Box>
        <Box className={styles.tags}>{tagsJSX}</Box>
        <Box className={styles.footShare}>
          <ShareButtons url={shareUrl} />
        </Box>
      </section>
      <Modal
        open={imgPopupState.isOpen}
        onClose={handleClosePopup}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={styles.popup}
      >
        <div className={styles.popupContent}>
          <button onClick={handleClosePopup} className={styles.popupClose} />
          <img src={imgPopupState.url} alt="" />
        </div>
      </Modal>
    </>
  );
};

export default ArticleSec;
